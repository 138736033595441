<template>
	<Layout>
		<Header>
			<b-button v-if="permissionEnabled('dealers', 'read')" type="is-secondary export" :loading="exporting" size="is-small" rounded outlined @click="exportDealers($event)">
				<svg-icon class="icon is-small" icon="export"></svg-icon>
				<span>Export</span>
			</b-button>
			<b-button v-if="permissionEnabled('dealers', 'create')" type="is-primary create" rounded @click="createDealer($event)">
				<svg-icon icon="plus" class="icon is-small"></svg-icon>
				<span>Add</span>
			</b-button>
		</Header>
		<section class="filter">
			<div class="filter__wrapper">
				<b-field>
					<b-input placeholder="Search" type="search" icon-right="magnify" @input="findByName" v-model="dealer.name"></b-input>
				</b-field>
			</div>
		</section>
		<Error v-if="errored" :icon="true" :back="true" />
		<Results v-if="!loading && !errored && dealers.length <= 0" />
		<div v-if="loading" class="columns is-multiline">
			<div v-for="n in pagination" :key="n" class="column is-12-mobile is-6-tablet is-4-desktop">
				<Placeholder />
			</div>
		</div>
		<transition-group name="filtering" class="filtering columns is-multiline" tag="div">
			<div v-for="d in dealers" :key="d.id" class="column is-12-mobile is-6-tablet is-4-desktop">
				<article class="block secondary" @click.self="updateDealer(d.id)">
					<b-image class="block__flag" :src="require(`@/assets/images/flags/${d.brand ? d.brand : 'brand-default'}.png`)"></b-image>
					<div class="block__content" @click="updateDealer(d.id)">
						<h3 class="block__name is-capitalized" translate="no">{{ d.name }}</h3>
						<p class="block__address is-capitalized">U$ {{ d.bonus }} {{ d.importer ? `• ${d.importer}` : '' }}</p>
					</div>
					<Trigger :id="d.id" :role="d.name" :name="d.name" :active="1" />
				</article>
			</div>
		</transition-group>
	</Layout>
</template>

<script>
import Layout from '@/layouts/Default'
import Header from '@/components/Header'
import Icon from '@/components/Icon'
import Placeholder from '@/components/placeholders/Role'
import Trigger from '@/components/triggers/Dealer'
import Error from '@/components/Error'
import Results from '@/components/Results'
import Modal from '@/components/modals/Dealer'
import { create, update } from '@/mixins/modal'
import Api from '@/services/api'
import eventHub from '@/services/eventHub'
import { successToast, errorToast } from '@/mixins/toast'
import { mapGetters } from 'vuex'
import '@/mixins/date'
import actionUrl from '@/mixins/actionurl'

export default {
	components: {
		Layout,
		Header,
		Placeholder,
		Error,
		Results,
		Trigger,
		'svg-icon': Icon
	},
	mixins: [
		actionUrl
	],
	data() {
		return {
			exporting: false,
			errored: false,
			loading: true,
			pagination: 15,
			dealer: {
				name: ''
			},
			dealers: []
		}
	},
	methods: {
		getUrlParams() {
			const url = location.href.split('/')
			const params = url[4]
			const type = url.find(el => el === params)

			switch (type) {
				case 'create':
					this.createDealer()
					break
				case 'edit':
					this.updateDealer(url[5])
			}
		},
		createDealer() {
			if (this.permissionEnabled('dealers', 'create')) {
				create('dealers', Modal, 'New')
			}
		},
		updateDealer(id) {
			if (this.permissionEnabled('dealers', 'edit')) {
				update('dealers', id, Modal, 'Edit')
			}
		},
		async getAllDealers() {
			try {
				const response = await Api.get('dealer/findAll')
				const { status } = response
				if (status === 200) {
					const { data } = response
					this.dealers = data
					this.loading = false
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		},
		async findByName() {
			try {
				const empty = /^\s*$/
				if (!empty.test(this.dealer.name)) {
					const response = await Api.post('dealer/findByName', {
						name: this.dealer.name
					})
					const { status } = response
					if (status === 200) {
						const { data } = response
						this.dealers = data
					}
				} else {
					await this.getAllDealers()
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		},
		async exportDealers() {
			this.exporting = true
			try {
				const response = await Api.post('dealer/export')
				const { status } = response
				if (status === 422) {
					errorToast('An <strong>error</strong> has occurred while exporting the criteria.')
				} else {
					const { message } = response.data
					successToast('All countries have been exported <strong>successfully</strong>.')
					setTimeout(() => {
						this.exporting = false
						const node = document.createElement('a')
						node.href = message
						node.click()
					}, 2000)
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.exporting = false
			}
		}
	},
	mounted() {
		this.getAllDealers()
		this.getUrlParams()
		eventHub.$on('reload-dealers', () => {
			this.getAllDealers()
		})
		eventHub.$on('edit-modal-dealer', obj => {
			update('dealers', obj.id, Modal, 'Edit')
		})
		eventHub.$on('delete-dealer', obj => {
			this.$buefy.dialog.alert({
				size: 'is-delete',
				type: 'is-outlined is-primary',
				title: 'Atenção!',
				message: '<span>Do you really want to <strong>remove</strong> this country?</span> <small>This action cannot be undone and impacts system operation directly.</small>',
				canCancel: true,
				focusOn: 'cancel',
				cancelText: 'No',
				confirmText: 'Yes',
				onConfirm: async () => {
					try {
						const response = await Api.delete(`dealer/destroy/${obj.id}`)
						const { status } = response
						if (status === 200) {
							successToast('The country has been <strong>removed</strong> successfully!')
							eventHub.$emit('reload-dealers')
						}
					} catch (e) {
						console.log(e)
					}
				}
			})
		})
	},
	destroyed() {
		eventHub.$off('reload-dealers')
		eventHub.$off('edit-modal-dealer')
		eventHub.$off('delete-dealer')
	},
	computed: {
		...mapGetters('user', ['permissionEnabled'])
	}
}
</script>
