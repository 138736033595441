<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit }">
		<form @submit.prevent="handleSubmit(saveDealer)">
			<header class="modal-card-head">
				<h4 class="modal-card-title">
					<span>{{ modalTitle(name) }} <strong>Country</strong></span>
					<span v-if="dealer.id">#{{ dealer.id }}</span>
				</h4>
			</header>
			<div class="modal-card-body">
				<b-loading :is-full-page="false" v-model="isOpening"></b-loading>
				<div class="modal-card mb-3">
					<div class="columns">
						<div class="column">
							<InputWithValidation rules="required|min:3" type="text" label="Name" size="is-medium" v-model="dealer.name" />
						</div>
						<div class="column is-one-third select-flag">
							<b-field label="Flag">
								<multiselect
									v-model="dealer.brand"
									tag-position="top"
									label="name"
									track-by="name"
									:max-height="222"
									:options="flags"
									:close-on-select="true"
									:hide-selected="true"
									:searchable="false"
									placeholder=""
									selectLabel=""
									selectedLabel=""
									deselectLabel="">
									<span slot="noResult">No flags found!</span>
									<template slot="option" slot-scope="props">
										<img class="option__image flag-icon" :src="require(`@/assets/images/flags/${props.option.id}.png`)" />
										<h3 class="flag-label">{{ props.option.name }}</h3>
									</template>
									<template slot="singleLabel" slot-scope="props">
										<img class="option__image" :src="require(`@/assets/images/flags/${props.option.id}.png`)" />
										<h3 class="flag-label">{{ props.option.name }}</h3>
									</template>
								</multiselect>
							</b-field>
						</div>
					</div>

					<InputWithValidation class="mb-4" type="text" label="Importer" size="is-medium" v-model="dealer.importer" />
					<InputWithValidation class="mb-4" rules="required|min:7|max:11" type="text" label="Bonus" size="is-medium" v-model="dealer.bonus" v-money="money" />

					<small class="modal-updated" v-if="dealer.updated_at">Last Update: {{ format(dealer.updated_at) }}</small>
				</div>
			</div>
			<footer class="modal-card-foot">
				<b-button class="is-rounded is-outlined is-danger" @click="$emit('close')">Close</b-button>
				<b-button native-type="submit" class="is-rounded is-primary" :loading="loading">Save</b-button>
			</footer>
		</form>
	</ValidationObserver>
</template>

<script>
import axios from 'axios'
import InputWithValidation from '@/components/inputs/InputWithValidation'
import { ValidationObserver } from 'vee-validate'
import Api from '@/services/api'
import eventHub from '@/services/eventHub'
import { successToast, errorToast } from '@/mixins/toast'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import '@/mixins/generic'
import { VMoney } from 'v-money'

export default {
	components: {
		InputWithValidation,
		ValidationObserver,
		Multiselect
	},
	directives: {
		money: VMoney
	},
	props: {
		id: {
			type: [Number, String],
			required: false
		},
		name: {
			type: String,
			required: true
		},
		root: {
			type: String,
			required: false
		}
	},
	data() {
		return {
			isOpening: false,
			loading: false,
			dealer: {
				type: 1,
				name: '',
				phone: '',
				document: '',
				code: '',
				address: '',
				city: '',
				state: '',
				neighborhood: ''
			},
			dealers: [],
			money: {
				decimal: ',',
				thousands: '.',
				prefix: 'U$ ',
				precision: 2
			},
			flags: [
				// { id: 'ad', name: 'ad' },
				// { id: 'ae', name: 'ae' },
				// { id: 'af', name: 'af' },
				// { id: 'ag', name: 'ag' },
				// { id: 'ai', name: 'ai' },
				// { id: 'al', name: 'al' },
				// { id: 'am', name: 'am' },
				// { id: 'ao', name: 'ao' },
				// { id: 'aq', name: 'aq' },
				{ id: 'ar', name: 'ar' },
				// { id: 'as', name: 'as' },
				// { id: 'at', name: 'at' },
				// { id: 'au', name: 'au' },
				// { id: 'aw', name: 'aw' },
				// { id: 'ax', name: 'ax' },
				// { id: 'az', name: 'az' },
				// { id: 'ba', name: 'ba' },
				// { id: 'bb', name: 'bb' },
				// { id: 'bd', name: 'bd' },
				// { id: 'be', name: 'be' },
				// { id: 'bf', name: 'bf' },
				// { id: 'bg', name: 'bg' },
				// { id: 'bh', name: 'bh' },
				// { id: 'bi', name: 'bi' },
				// { id: 'bj', name: 'bj' },
				// { id: 'bl', name: 'bl' },
				// { id: 'bm', name: 'bm' },
				// { id: 'bn', name: 'bn' },
				{ id: 'bo', name: 'bo' },
				// { id: 'bq', name: 'bq' },
				// { id: 'br', name: 'br' },
				// { id: 'bs', name: 'bs' },
				// { id: 'bt', name: 'bt' },
				// { id: 'bv', name: 'bv' },
				// { id: 'bw', name: 'bw' },
				// { id: 'by', name: 'by' },
				// { id: 'bz', name: 'bz' },
				// { id: 'ca', name: 'ca' },
				// { id: 'cc', name: 'cc' },
				// { id: 'cd', name: 'cd' },
				// { id: 'cf', name: 'cf' },
				// { id: 'cg', name: 'cg' },
				// { id: 'ch', name: 'ch' },
				// { id: 'ci', name: 'ci' },
				// { id: 'ck', name: 'ck' },
				{ id: 'cl', name: 'cl' },
				// { id: 'cm', name: 'cm' },
				// { id: 'cn', name: 'cn' },
				{ id: 'co', name: 'co' },
				{ id: 'cr', name: 'cr' },
				// { id: 'cu', name: 'cu' },
				// { id: 'cv', name: 'cv' },
				// { id: 'cw', name: 'cw' },
				// { id: 'cx', name: 'cx' },
				// { id: 'cy', name: 'cy' },
				// { id: 'cz', name: 'cz' },
				// { id: 'de', name: 'de' },
				// { id: 'dj', name: 'dj' },
				// { id: 'dk', name: 'dk' },
				// { id: 'dm', name: 'dm' },
				{ id: 'do', name: 'do' },
				// { id: 'dz', name: 'dz' },
				{ id: 'ec', name: 'ec' },
				// { id: 'ee', name: 'ee' },
				// { id: 'eg', name: 'eg' },
				// { id: 'eh', name: 'eh' },
				// { id: 'er', name: 'er' },
				// { id: 'es', name: 'es' },
				// { id: 'et', name: 'et' },
				// { id: 'fi', name: 'fi' },
				// { id: 'fj', name: 'fj' },
				// { id: 'fk', name: 'fk' },
				// { id: 'fm', name: 'fm' },
				// { id: 'fo', name: 'fo' },
				// { id: 'fr', name: 'fr' },
				// { id: 'ga', name: 'ga' },
				// { id: 'gb-eng', name: 'gb-eng' },
				// { id: 'gb-nir', name: 'gb-nir' },
				// { id: 'gb-sct', name: 'gb-sct' },
				// { id: 'gb-wls', name: 'gb-wls' },
				// { id: 'gb', name: 'gb' },
				// { id: 'gd', name: 'gd' },
				// { id: 'ge', name: 'ge' },
				// { id: 'gf', name: 'gf' },
				// { id: 'gg', name: 'gg' },
				// { id: 'gh', name: 'gh' },
				// { id: 'gi', name: 'gi' },
				// { id: 'gl', name: 'gl' },
				// { id: 'gm', name: 'gm' },
				// { id: 'gn', name: 'gn' },
				// { id: 'gp', name: 'gp' },
				// { id: 'gq', name: 'gq' },
				// { id: 'gr', name: 'gr' },
				// { id: 'gs', name: 'gs' },
				{ id: 'gt', name: 'gt' },
				// { id: 'gu', name: 'gu' },
				// { id: 'gw', name: 'gw' },
				// { id: 'gy', name: 'gy' },
				// { id: 'hk', name: 'hk' },
				// { id: 'hm', name: 'hm' },
				// { id: 'hn', name: 'hn' },
				// { id: 'hr', name: 'hr' },
				// { id: 'ht', name: 'ht' },
				// { id: 'hu', name: 'hu' },
				// { id: 'id', name: 'id' },
				// { id: 'ie', name: 'ie' },
				// { id: 'il', name: 'il' },
				// { id: 'im', name: 'im' },
				// { id: 'in', name: 'in' },
				// { id: 'io', name: 'io' },
				// { id: 'iq', name: 'iq' },
				// { id: 'ir', name: 'ir' },
				// { id: 'is', name: 'is' },
				// { id: 'it', name: 'it' },
				// { id: 'je', name: 'je' },
				{ id: 'jm', name: 'jm' },
				// { id: 'jo', name: 'jo' },
				// { id: 'jp', name: 'jp' },
				// { id: 'ke', name: 'ke' },
				// { id: 'kg', name: 'kg' },
				// { id: 'kh', name: 'kh' },
				// { id: 'ki', name: 'ki' },
				// { id: 'km', name: 'km' },
				// { id: 'kn', name: 'kn' },
				// { id: 'kp', name: 'kp' },
				// { id: 'kr', name: 'kr' },
				// { id: 'kw', name: 'kw' },
				// { id: 'ky', name: 'ky' },
				// { id: 'kz', name: 'kz' },
				// { id: 'la', name: 'la' },
				// { id: 'lb', name: 'lb' },
				// { id: 'lc', name: 'lc' },
				// { id: 'li', name: 'li' },
				// { id: 'lk', name: 'lk' },
				// { id: 'lr', name: 'lr' },
				// { id: 'ls', name: 'ls' },
				// { id: 'lt', name: 'lt' },
				// { id: 'lu', name: 'lu' },
				// { id: 'lv', name: 'lv' },
				// { id: 'ly', name: 'ly' },
				// { id: 'ma', name: 'ma' },
				// { id: 'mc', name: 'mc' },
				// { id: 'md', name: 'md' },
				// { id: 'me', name: 'me' },
				// { id: 'mf', name: 'mf' },
				// { id: 'mg', name: 'mg' },
				// { id: 'mh', name: 'mh' },
				// { id: 'mk', name: 'mk' },
				// { id: 'ml', name: 'ml' },
				// { id: 'mm', name: 'mm' },
				// { id: 'mn', name: 'mn' },
				// { id: 'mo', name: 'mo' },
				// { id: 'mp', name: 'mp' },
				// { id: 'mq', name: 'mq' },
				// { id: 'mr', name: 'mr' },
				// { id: 'ms', name: 'ms' },
				// { id: 'mt', name: 'mt' },
				// { id: 'mu', name: 'mu' },
				// { id: 'mv', name: 'mv' },
				// { id: 'mw', name: 'mw' },
				// { id: 'mx', name: 'mx' },
				// { id: 'my', name: 'my' },
				// { id: 'mz', name: 'mz' },
				// { id: 'na', name: 'na' },
				// { id: 'nc', name: 'nc' },
				// { id: 'ne', name: 'ne' },
				// { id: 'nf', name: 'nf' },
				// { id: 'ng', name: 'ng' },
				// { id: 'ni', name: 'ni' },
				// { id: 'nl', name: 'nl' },
				// { id: 'no', name: 'no' },
				// { id: 'np', name: 'np' },
				// { id: 'nr', name: 'nr' },
				// { id: 'nu', name: 'nu' },
				// { id: 'nz', name: 'nz' },
				// { id: 'om', name: 'om' },
				{ id: 'pa', name: 'pa' },
				{ id: 'pe', name: 'pe' },
				// { id: 'pf', name: 'pf' },
				// { id: 'pg', name: 'pg' },
				// { id: 'ph', name: 'ph' },
				// { id: 'pk', name: 'pk' },
				// { id: 'pl', name: 'pl' },
				// { id: 'pm', name: 'pm' },
				// { id: 'pn', name: 'pn' },
				{ id: 'pr', name: 'pr' },
				// { id: 'ps', name: 'ps' },
				// { id: 'pt', name: 'pt' },
				// { id: 'pw', name: 'pw' },
				{ id: 'py', name: 'py' },
				// { id: 'qa', name: 'qa' },
				// { id: 're', name: 're' },
				// { id: 'ro', name: 'ro' },
				// { id: 'rs', name: 'rs' },
				// { id: 'ru', name: 'ru' },
				// { id: 'rw', name: 'rw' },
				// { id: 'sa', name: 'sa' },
				// { id: 'sb', name: 'sb' },
				// { id: 'sc', name: 'sc' },
				// { id: 'sd', name: 'sd' },
				// { id: 'se', name: 'se' },
				// { id: 'sg', name: 'sg' },
				// { id: 'sh', name: 'sh' },
				// { id: 'si', name: 'si' },
				// { id: 'sj', name: 'sj' },
				// { id: 'sk', name: 'sk' },
				// { id: 'sl', name: 'sl' },
				// { id: 'sm', name: 'sm' },
				// { id: 'sn', name: 'sn' },
				// { id: 'so', name: 'so' },
				// { id: 'sr', name: 'sr' },
				// { id: 'ss', name: 'ss' },
				// { id: 'st', name: 'st' },
				// { id: 'sv', name: 'sv' },
				// { id: 'sx', name: 'sx' },
				// { id: 'sy', name: 'sy' },
				// { id: 'sz', name: 'sz' },
				// { id: 'tc', name: 'tc' },
				// { id: 'td', name: 'td' },
				// { id: 'tf', name: 'tf' },
				// { id: 'tg', name: 'tg' },
				// { id: 'th', name: 'th' },
				// { id: 'tj', name: 'tj' },
				// { id: 'tk', name: 'tk' },
				// { id: 'tl', name: 'tl' },
				// { id: 'tm', name: 'tm' },
				// { id: 'tn', name: 'tn' },
				// { id: 'to', name: 'to' },
				// { id: 'tr', name: 'tr' },
				{ id: 'tt', name: 'tt' },
				// { id: 'tv', name: 'tv' },
				// { id: 'tw', name: 'tw' },
				// { id: 'tz', name: 'tz' },
				// { id: 'ua', name: 'ua' },
				// { id: 'ug', name: 'ug' },
				// { id: 'um', name: 'um' },
				// { id: 'us', name: 'us' },
				{ id: 'uy', name: 'uy' },
				// { id: 'uz', name: 'uz' },
				// { id: 'va', name: 'va' },
				// { id: 'vc', name: 'vc' },
				// { id: 've', name: 've' },
				// { id: 'vg', name: 'vg' },
				// { id: 'vi', name: 'vi' },
				// { id: 'vn', name: 'vn' },
				// { id: 'vu', name: 'vu' },
				// { id: 'wf', name: 'wf' },
				// { id: 'ws', name: 'ws' },
				// { id: 'xk', name: 'xk' },
				// { id: 'ye', name: 'ye' },
				// { id: 'yt', name: 'yt' },
				// { id: 'za', name: 'za' },
				// { id: 'zm', name: 'zm' },
				// { id: 'zw', name: 'zw' },
			]
		}
	},
	methods: {
		completeAdress(cep) {
			let d = this.dealer
			axios
				.get(`https://viacep.com.br/ws/${cep}/json/`)
				.then(response => {
					let a = response.data
					d.address = a.logradouro
					d.city = a.localidade
					d.state = a.uf
					d.neighborhood = a.bairro
					// console.log(d, a)
				})
				.catch(error => {
					console.log('error', error)
				})
		},
		async saveDealer() {
			this.name === 'New' ? await this.store() : await this.update()
		},
		async store() {
			try {
				this.loading = true
				const response = await Api.post(`dealer/store`, this.dealer)
				const { status } = response
				if (status === 201) {
					// const { message } = response.data
					this.$emit('close')
					history.pushState({}, '', '/dealers')
					successToast('The country has been <strong>registered</strong> successfully!')
					eventHub.$emit('reload-dealers')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					// const { message } = e.data
					errorToast('An <strong>error</strong> has occurred while creating the country!')
				}
			} finally {
				this.loading = false
			}
		},
		async update() {
			try {
				this.loading = true
				const response = await Api.put(`dealer/update/${this.id}`, this.dealer)
				const { status } = response
				if (status === 200) {
					// const { message } = response.data
					this.$emit('close')
					history.pushState({}, '', '/dealers')
					successToast('The country has been <strong>updated</strong> successfully!')
					eventHub.$emit('reload-dealers')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					// const { message } = e.data
					errorToast('An <strong>error</strong> has occurred while updating the dealer!')
				}
			} finally {
				this.loading = false
			}
		},
		async findById() {
			if (this.name === 'Edit') {
				this.isOpening = true
				try {
					const response = await Api.get(`dealer/findById/${this.id}`)
					const { status } = response
					if (status === 200) {
						const { data } = response
						const brand = (this.flags.find(f => f.id == data.brand) || null)
						this.dealer = { ...data, brand }
						this.isOpening = false
					}
				} catch (e) {
					console.log(e)
				}
			}
		},
		async getAllDealers() {
			try {
				const response = await Api.get('dealer/findAll')

				if (response.status === 200) {
					this.dealers = response.data.filter((d) => {
						if (d.group_id && d.group_id != this.dealer.group_id) {
							d.$isDisabled = true
						}

						return d.id != this.dealer.id
					})
				}
			} catch (e) {
				console.log(e)
			}
		}
	},
	mounted() {
		this.findById()
		this.getAllDealers()
	}
}
</script>
